import Echo from "laravel-echo";
import pusher from "pusher-js";
window.Pusher = pusher;
import Vue from "vue";
import VuetifyToast from "vuetify-toast-snackbar-ng";
import setup from "./interceptors/interceptors.js";
import { VueMaskDirective } from "v-mask";
import VueRouter from "vue-router";
import Loader from "./base_components/base/Loader.vue";
import VCustomTitle from "./global_components/VCustomTitle.vue";
import VCustomCard from "./global_components/VCustomCard.vue";
import VClienteComponent from "./global_components/VClienteComponent.vue";
import VCustomMenuCalendar from "./global_components/VCustomMenuCalendar.vue";

import VConfirmDialog from "./global_components/VConfirmDialog.vue";

import Nl2br from "vue-nl2br";

import dynamic_select from "./components/general/dynamicSelect.vue";
import cita_picker from "./components/general/planpicker/citapicker.vue";
import dateSelect from "./components/general/dateSelect.vue";
import axios from "axios";

export default {
    init() {
        window.Echo = new Echo({
            broadcaster: "pusher",
            key: "daca2373c5bdf21adbfb",
            cluster: "eu",
            authEndpoint: "api/broadcasting/auth",
            auth: {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("id_token"),
                },
            },
        });

        setup();

        Vue.directive("mask", VueMaskDirective);

        Vue.use(VueRouter);

        Vue.component("loader", Loader);

        Vue.component("v-custom-title", VCustomTitle);

        Vue.component("v-custom-card", VCustomCard);

        Vue.component("v-cliente-component", VClienteComponent);

        Vue.component("v-custom-menu-calendar", VCustomMenuCalendar);
        Vue.component("v-confirm-dialog", VConfirmDialog);
        Vue.component("nl2br", Nl2br);
        Vue.component("dynamic_select", dynamic_select);
        Vue.component("cita-picker", cita_picker);
        Vue.component("date-select", dateSelect);
        window.axios = axios;

        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";

        axios.defaults.headers.common["Cache-Control"] = "no-cache";
        axios.defaults.headers.common["Vary"] = "*";

        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
            "id_token"
        )}`;
        axios.defaults.headers.common.user_id = `${localStorage.getItem(
            "user_id"
        )}`;
        axios.defaults.withCredentials = true;

        Vue.filter("format_date", function (value) {
            let str = (value + "T" + " ")
                .split("T")[0]
                .split(" ")[0]
                .split("-");
            return `${str[2]}-${str[1]}-${str[0]}`;
        });

        Vue.filter("format_date_time", function (value) {
            let str = (value + "T" + " ")
                .split("T");
            let time = str[1].split(" ")[0]
            .split(":");
            str = str[0].split(" ")[0]
            .split("-");
                
            return `${str[2]}-${str[1]}-${str[0]} ${time[0]}:${time[1]}`;
        });

        Vue.filter("format_precio", function (value) {
            return parseFloat(value).toFixed(2).replace('.',',') + ' €';
        });

        Vue.filter("format_number", function (value) {
            return parseFloat(value).toFixed(2).replace('.',',');
        });

        Vue.filter("hora_formated", function (val) {
            if (val == null) return "";

            let str = val.toString().split(".");
            if (str.length > 1) {
                return str[0].toString().padStart(2, "0") + ":30";
            }
            return val.toString().padStart(2, "0") + ":00";
        });

        // Funcion global para capitalizar el texto
        Vue.filter("capitalize", function (value) {
            if (!value) return '';
            return value
                .toString()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        })

        Vue.use(VuetifyToast, {
            x: "right",
            y: "top",
            color: "info",
            icon: "mdi-info",
            timeout: 3000,
            dismissable: true,
            autoHeight: false,
            multiLine: false,
            vertical: false,
            shorts: {
                error: {
                    color: "red",
                },
                sucs: {
                    color: "green",
                },
                warn: {
                    color: "orange",
                },
            },
            property: "$toast",
        });
    },
};
