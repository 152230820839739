<template>
    <v-container>
        <v-alert :value="alert" type="info" text border="top">
            <v-row align="center">
                <v-col class="grow">
                    <div v-for="(message, index) in alerts" :key="index">
                        {{ message }}
                    </div>
                </v-col>
                <v-col class="shrink">
                    <v-btn color="blue" @click="handleAlertClose">Ok</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-card>
            <v-toolbar flat color="#1d2735" dark>
                <v-toolbar-title>Guardar / Editar Ingreso</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="validForm">
                    <!-- Datos generales del importe -->
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <cliente-select
                                :extra="ingreso.cliente"
                                :outlined="true"
                                prepend-icon="mdi-account-search-outline"
                                v-model="ingreso.cliente_id"
                                :error-messages="
                                    errors.errors['ingreso.cliente_id']
                                        ? errors.errors['ingreso.cliente_id'][0]
                                        : null
                                "
                                :items="usuarios"
                                item-value="id"
                                item-text="nombres"
                                label="Seleccione un Cliente"
                            >
                            </cliente-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <date-select
                                label="Fecha"
                                v-model="ingreso.fecha"
                                :outlined="true"
                            ></date-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                outlined
                                filled
                                append-icon="mdi-currency-eur"
                                :error-messages="
                                    errors.errors['ingreso.importe']
                                        ? errors.errors['ingreso.importe'][0]
                                        : null
                                "
                                v-model="ingreso.importe"
                                label="Importe del ingreso"
                                :rules="[
                                    rules.required,
                                    rules.decimal,
                                    rules.min,
                                    ImporteCorrecto,
                                ]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                outlined
                                small-chips
                                deletable-chips
                                multiple
                                clearable
                                prepend-icon="mdi-account-search-outline"
                                v-model="facturas_selected"
                                :items="facturas"
                                item-value="id"
                                item-text="nro_anio_factura"
                                label="Seleccione una Factura"
                                :rules="[rules.requiredMultiple]"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <!-- Datos de las facturas asiciadas -->
                    <h2 class="text-center mb-5">Importes por facturas</h2>

                    <!-- Si la relacion ingreso_factura tiene data -->
                    <template v-if="ingreso.ingreso_factura.length > 0">
                        <v-row
                            dense
                            justify="space-between"
                            v-for="(item, index) in ingreso.ingreso_factura"
                            :key="index"
                        >
                            <v-col cols="12" md="2">
                                <v-chip
                                    class="px-3 white--text d-flex justify-center"
                                    color="blue"
                                    >{{ item.nro_anio_factura }}</v-chip
                                >
                            </v-col>
                            <v-col cols="12" :md="$route.query.id ? '5' : '3'">
                                <v-text-field
                                    outlined
                                    v-model="item.total_factura"
                                    label="Total facturado"
                                    append-icon="mdi-currency-eur"
                                    readonly
                                    dense
                                ></v-text-field>
                                <!-- <v-autocomplete
                                    outlined
                                    v-model="total"
                                    :items="facturas"
                                    label="Total"
                                    append-icon="mdi-currency-eur"
                                >
                                </v-autocomplete>
                                <v-text-field 
                                    class="my-input" 
                                    filled 
                                    :error-messages="errors.errors.codigo ? errors.errors.codigo[0] : null" 
                                    v-model="ingreso.codigo" 
                                    label="Codigo" 
                                    required
                                ></v-text-field> -->
                            </v-col>
                            <v-col
                                v-if="$route.query.id == null"
                                cols="12"
                                md="3"
                            >
                                <v-text-field
                                    outlined
                                    v-model="item.resto"
                                    label="Restante por pagar"
                                    append-icon="mdi-currency-eur"
                                    readonly
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :md="$route.query.id ? '5' : '3'">
                                <v-text-field
                                    outlined
                                    filled
                                    append-icon="mdi-currency-eur"
                                    v-model="item.importe"
                                    label="Importe individual"
                                    :rules="
                                        item.resto > 0
                                            ? [
                                                  rules.required,
                                                  rules.decimal,
                                                  rules.mayorQue(
                                                      item.total_factura
                                                  ),
                                              ]
                                            : []
                                    "
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    <!-- En caso contrario usar la columna factura_id -->
                    <template v-else>
                        <v-row dense justify="space-between">
                            <v-col cols="12" md="2">
                                <v-chip
                                    class="px-3 white--text d-flex justify-center"
                                    color="blue"
                                    >{{ factura.nro_anio_factura }}</v-chip
                                >
                            </v-col>
                            <v-col cols="12" :md="$route.query.id ? '5' : '3'">
                                <v-text-field
                                    outlined
                                    v-model="factura.total"
                                    label="Total facturado"
                                    append-icon="mdi-currency-eur"
                                    readonly
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col
                                v-if="
                                    $route.query.id == null || factura.resto > 0
                                "
                                cols="12"
                                md="3"
                            >
                                <v-text-field
                                    outlined
                                    v-model="factura.resto"
                                    label="Restante por pagar"
                                    append-icon="mdi-currency-eur"
                                    readonly
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :md="$route.query.id ? '5' : '3'">
                                <v-text-field
                                    outlined
                                    filled
                                    append-icon="mdi-currency-eur"
                                    v-model="ingreso.importe"
                                    label="Importe individual"
                                    :rules="[
                                        rules.required,
                                        rules.decimal,
                                        rules.mayorQue(factura.total),
                                    ]"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>

                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                v-model="ingreso.descripcion"
                                label="Descripción del ingreso"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-row class="mt-3">
            <!-- Botones Navegacion -->
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="$router.push('/lista-ingresos')"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-arrow-left-bold-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Volver</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="saveIngreso"
                            :loading="isloading"
                            :disabled="isloading"
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-content-save-all</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Guardar Ingreso</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ClienteSelect from "../../../components/general/Selects/ClienteSelect.vue";

export default {
    components: {
        ClienteSelect,
    },
    data() {
        return {
            ingreso_factura_iniciales: [],
            rules: {
                required: (value) => !!value || "Requerido.",
                requiredMultiple: (value) =>
                    (value && value.length > 0) ||
                    "Debe seleccionar al menos un elemento",
                number: (value) => !isNaN(value) || "Debe ser un número",
                mayorQue: (total) => (value) => {
                    const importe = parseFloat(value);
                    return (
                        total >= importe ||
                        `El importe no debe ser mayor al total de ${total}`
                    );
                },
                decimal: (value) => {
                    // Verifica que el valor sea un número decimal válido
                    const decimalPattern = /^-?\d+(\.\d+)?$/;
                    return (
                        decimalPattern.test(parseFloat(value)) ||
                        "Por favor, ingrese un número decimal válido."
                    );
                },
                min: (value) => {
                    const minValue = 1;
                    return (
                        value >= minValue ||
                        `El número debe ser mayor o igual a ${minValue}.`
                    );
                },
            },
            ingreso: {
                id: null,
                codigo: "",
                fecha: "",
                importe: null,
                descripcion: "",
                user_id: localStorage.getItem("user_id"),
                proyecto_id: null,
                factura_id: null,
                facturas: {}, // la relacion en el modelo se llama "facturas" (plural) pero es una relacion uno a uno
                ingreso_factura: [],
            },
            usuarios: [],
            facturas: [],
            factura: {},
            proyectos: [],
            validForm: false,
            validImportes: true,
            validRestos: true,
            facturas_selected: [],
            importe_total: 0, // Se usa para almacenar la suma de los restantes a pagar de cada factura
            alert: false,
            alerts: [],
        };
    },
    watch: {
        facturas_selected: function (val) {
            // Crear un mapa para actualización más fácil
            const facturaMap = new Map(this.facturas.map((f) => [f.id, f]));

            // Agregar nuevas facturas y calcular el total
            val.forEach((selectedId) => {
                const factura = facturaMap.get(selectedId);

                if (factura) {
                    // Verificar si la factura ya existe en ingreso_factura
                    const existing = this.ingreso.ingreso_factura.find(
                        (ef) => ef.factura_id === selectedId
                    );

                    if (!existing) {
                        let importe_individual;
                        if (this.ingreso.factura_id != null) {
                            importe_individual = this.ingreso.importe;
                        } else {
                            importe_individual = factura.resto;
                        }

                        this.importe_total += parseFloat(importe_individual);

                        if (this.importe_total > this.ingreso.importe) {
                            const result =
                                this.importe_total - parseFloat(factura.resto);
                            importe_individual = (
                                this.ingreso.importe - result
                            ).toFixed(2);
                        }

                        this.ingreso.ingreso_factura.push({
                            id: null,
                            factura_id: factura.id,
                            ingreso_id: this.ingreso.id,
                            importe: importe_individual,
                            total_factura: factura.total,
                            resto: factura.resto,
                            nro_anio_factura: factura.nro_anio_factura,
                        });
                    }
                }
            });
            // Eliminar facturas que ya no están seleccionadas
            this.ingreso.ingreso_factura = this.ingreso.ingreso_factura.filter(
                (ef) => val.includes(ef.factura_id)
            );
            this.importe_total = 0;
            this.importe_total = this.ingreso.ingreso_factura.reduce(
                (sum, item) => sum + parseFloat(item.importe),
                0
            );
        },
        "ingreso.cliente_id": async function (val) {
            console.log(this.facturas_selected, "facturas_selected");

            await this.getFacturasByCliente(val);
            this.facturas_selected = this.facturas_selected.filter(
                (element) => {
                    return (
                        this.facturas.find((factura) => {
                            console.log(factura.id, element, "element");
                            return factura.id == element;
                        }) != null
                    );
                }
            );
        },
        "ingreso.ingreso_factura": {
            deep: true,
            handler(val) {
                // Calcular el total de los restos de las facturas seleccionadas

                let totalImportes = 0;
                let totalRestos = 0;
                val.forEach((element) => {
                    totalImportes += parseFloat(element.importe);
                    totalRestos += parseFloat(element.resto);
                });

                if (totalImportes > this.ingreso.importe) {
                    this.validImportes = false;
                } else {
                    this.validImportes = true;
                }

                if (this.$route.query.id == null) {
                    if (totalRestos < this.ingreso.importe) {
                        this.validRestos = false;
                    } else {
                        this.validRestos = true;
                    }
                }
            },
        },
        "ingreso.importe": function (val) {
            const facturaMap = new Map(this.facturas.map((f) => [f.id, f]));
            console.log(facturaMap, "map");
            let total = val;
            this.ingreso.ingreso_factura?.forEach((element) => {
                const factura = facturaMap.get(element.factura_id);
                const resto = factura.resto;

                if (resto) {
                    let diff = total - resto;
                    if (diff < 0) {
                        element.importe = total;
                        total = "0";
                    } else {
                        element.importe = resto;
                        total -= resto;
                    }
                }
            });

            this.importe_total = 0;
        },
    },
    created() {
        if (this.$route.query.id) {
            this.getIngresoById(this.$route.query.id);
        } else {
            this.getRandomCode();
        }
        if (this.$rofute.query.identi && this.$route.query.tipo) {
            this.ingreso.codigo =
                this.$route.query.tipo.substring(0, 3) +
                this.$route.query.identi;
        }
    },

    methods: {
        // Acciones de Ingreso
        async getIngresoById(ingreso_id) {
            const response = await axios.get(
                `api/get-ingreso-by-id/${ingreso_id}`
            );

            this.ingreso = response.data;
            this.ingreso_factura_iniciales = JSON.parse(
                JSON.stringify(response.data.ingreso_factura)
            );
            if (this.ingreso.cliente_id == null) {
                this.ingreso.cliente_id = res.data.proyecto.usuario_id;
            }

            await this.getFacturasByCliente(this.ingreso.cliente_id);
            await this.setFacturasSelected();

            this.facturas_selected = this.facturas_selected.filter(
                (element) => {
                    return (
                        this.facturas.find((factura) => {
                            console.log(factura.id, element, "element");
                            return factura.id == element;
                        }) != null
                    );
                }
            );
        },
        saveIngreso() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.validForm) {
                if (this.validImportes && this.validRestos) {
                    axios
                        .post("api/save-ingreso", this.ingreso)
                        .then((res) => {
                            if (res.data.error != null) {
                                this.$toast.error(res.data.error);
                            } else {
                                // Extraer solo los mensajes
                                this.alerts = res.data.messages.map(
                                    (msgObj) => Object.values(msgObj)[0]
                                );
                                this.alert = true;
                                this.$toast.sucs("Ingreso guardado con exito");
                            }
                            // this.$router.push("/lista-ingresos");
                        })
                        .catch((error) => {
                            const errorMessage = error.response.data.error;
                            this.$toast.error(errorMessage);
                        });
                } else {
                    let message = "";
                    if (!this.validImportes)
                        message =
                            "La suma de los importes individuales no puede ser mayor al importe del ingreso";
                    if (!this.validRestos)
                        message =
                            "El importe del ingreso es mayor que la suma de los restantes apagar de cada factura seleccionada";
                    this.$toast.error(message);
                }
            }
        },

        // Metodos auxiliares
        async getFacturasByCliente(id) {
            const response = await axios.get(
                `api/get-facturas-by-cliente/${id}`,
                {
                    params: {
                        tipo: 1,
                        newIngreso: this.$route.query.id != null ? false : true, // Si es nuevo manda 1, si es edicion manda 0
                    },
                }
            );
            this.facturas = response.data;
            console.log("facturas_antes", this.facturas);
            this.ingreso_factura_iniciales.forEach((ele) => {
                const factura = this.facturas.find((fact) => {
                    return fact.id == ele.factura_id;
                });
                console.log("factura", factura, ele);
                if (factura != null) {
                    factura.resto = parseFloat(factura.resto) + ele.importe;
                }
            });
            console.log("facturas_despues", this.facturas);
        },
        getRandomCode() {
            let randomChars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let result = "";
            for (var i = 0; i < 5; i++) {
                result += randomChars.charAt(
                    Math.floor(Math.random() * randomChars.length)
                );
            }
            this.ingreso.codigo = result;
        },
        async setFacturasSelected() {
            if (this.ingreso.ingreso_factura.length > 0) {
                this.ingreso.ingreso_factura.forEach((element) => {
                    this.facturas_selected.push(element.factura_id);
                });
            } else {
                this.facturas_selected.push(this.ingreso.factura_id);
                this.factura =
                    this.ingreso.factura_id != null
                        ? this.ingreso.facturas
                        : {};
            }
        },
        handleAlertClose() {
            this.alert = false; // Esto se ejecuta cuando el alert se cierra
            this.$router.push("/lista-ingresos");
        },
        ImporteCorrecto(value) {
            console.log(
                "importeCorrecto",
                value,
                this.totalLineas,
                value == this.totalLineas
            );
            return (
                !!(value == this.totalLineas) ||
                "El importe de facturas y el importe del ingreso no coinciden."
            );
        },
        // En desuso
        /*getAllProyectos() {
            // axios.get(`api/get-all-proyectos`).then(
            axios.get(`api/get-proyectos-activos`).then(
                (res) => {
                    this.proyectos = res.data;
                },
                (res) => {
                    this.$toast.error("Error consultando proyectos");
                }
            );
        },*/
    },
    computed: {
        isloading() {
            return this.$store.getters.getloading;
        },
        errors() {
            return this.$store.getters.geterrors;
        },

        totalLineas() {
            let res = 0;
            this.ingreso.ingreso_factura.forEach((item) => {
                res += parseFloat(item.importe);
            });
            return res;
        },
        /*facturas_added(){
            let result =  this.facturas;
            if(this.ingreso.ingreso_factura.length > 0){
                this.ingreso.ingreso_factura.forEach(ingreso_factura => {
                    if(this.facturas.find(factura => {
                        factura.id == ingreso_factura.factura_id;
                    }) == null){
                        result.push(ingreso_factura.factura);
                    }
                });
            }else{
                if(this.facturas.find(factura => factura.id == this.ingreso.factura_id) == null){
                    result.push(this.ingreso.factura_id);
                }
            }

            return result;
        },*/
    },
};
</script>

<style media="screen">
.my-input input {
    text-transform: uppercase;
}
</style>
