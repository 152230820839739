<template>
    <v-container>
        <loader v-if="isloading"></loader>
        <v-card>
            <v-toolbar flat color="#1d2735" dark>
                <v-toolbar-title>Guardar / Editar Ticket</v-toolbar-title>
            </v-toolbar>

            <v-row class="ma-1 pa-3">
                <v-col cols="12">
                    <v-form class="mt-3">
                        <v-row>
                            <v-col cols="6" md="2">
                                <v-autocomplete
                                    label="Estado"
                                    outlined
                                    dense
                                    v-model="ticket.id_estado_ticket"
                                    :items="estados"
                                    item-text="descripcion"
                                    item-value="id"
                                    :readonly="!edit"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-autocomplete
                                    label="Departamento"
                                    outlined
                                    dense
                                    v-model="ticket.id_departamento"
                                    :items="departamentos"
                                    item-text="descripcion"
                                    :readonly="!edit"
                                    item-value="id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" md="2">
                                <v-autocomplete
                                    label="Urgencia"
                                    outlined
                                    dense
                                    v-model="ticket.id_urgencia"
                                    :items="urgencia"
                                    item-text="descripcion"
                                    item-value="id"
                                    :readonly="!edit"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" md="2">
                                <date-select
                                    :outlined="true"
                                    label="Fecha de realización"
                                    v-model="ticket.fecha_realizacion"
                                    dense
                                    rounded
                                    :disabled="isDateSelectDisabled"
                                ></date-select>
                            </v-col>
                            <v-col cols="6" md="2">
                                <date-select
                                    :outlined="true"
                                    label="Fecha de finalización"
                                    v-model="ticket.fecha_finalizacion"
                                    dense
                                    rounded
                                    :disabled="isDateSelectDisabled"
                                ></date-select>
                            </v-col>
                            <v-col cols="6" md="1">
                                <v-checkbox
                                    dense
                                    v-model="ticket.notificar"
                                    label="Notificar"
                                    :readonly="!edit"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="6" md="1">
                                <v-checkbox
                                    dense
                                    :readonly="!edit"
                                    v-model="ticket.interno"
                                    label="Interno"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    :readonly="!edit"
                                    label="Nombre Externo"
                                    v-model="ticket.nombre_ext"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Email Externo"
                                    v-model="ticket.email_ext"
                                    dense
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    label="Cliente"
                                    :readonly="!edit"
                                    outlined
                                    dense
                                    v-model="ticket.id_usuario"
                                    :items="clientes"
                                    item-text="nombres"
                                    item-value="id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    label="Proyecto"
                                    outlined
                                    :readonly="!edit"
                                    dense
                                    v-model="ticket.id_proyecto"
                                    :items="proyectos"
                                    item-text="nombre"
                                    item-value="id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    label="Responsable"
                                    outlined
                                    :readonly="!edit"
                                    dense
                                    v-model="ticket.id_responsable"
                                    :items="responsables"
                                    item-text="nombre"
                                    item-value="id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Titulo"
                                    outlined
                                    dense
                                    v-model="ticket.titulo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <RichTextComponent
                                    v-model="ticket.descripcion"
                                    uploadUrl="api/ticket/image"
                                ></RichTextComponent>
                                <h4
                                    style="
                                        padding-top: 1rem;
                                        padding-bottom: 1rem;
                                    "
                                >
                                    Descripción Interna
                                </h4>
                                <RichTextComponent
                                    v-model="ticket.descripcion_interna"
                                    uploadUrl="api/ticket/image"
                                ></RichTextComponent>
                                <!--v-textarea--
                                    dense
                                    outlined
                                    label="Descripcion"
                                    rows="6"
                                    v-model="ticket.descripcion"
                                >
                                </RichTextComponent!--v-textarea-->
                            </v-col>
                        </v-row>
                        <ArchivosComponentVue
                            v-model="ticket.archivos"
                        ></ArchivosComponentVue>
                        <!-- <v-row>
                            <v-col cols="12">
                                <v-btn
                                    v-if="!ticket.id"
                                    color="success"
                                    class="white--text"
                                    @click="saveTicket"
                                    >Guardar</v-btn
                                >
                                <v-btn
                                    @click="saveTicket"
                                    v-if="ticket.id"
                                    color="success"
                                    class="white--text"
                                    >Actualizar</v-btn
                                >
                                <v-btn
                                    @click="deleteTicket(ticket.id)"
                                    v-if="ticket.id"
                                    color="red"
                                    class="white--text"
                                    >Eliminar</v-btn
                                >
                            </v-col>
                        </v-row> -->
                    </v-form>
                </v-col>
            </v-row>
        </v-card>

        <v-row class="mt-3">
            <!-- Botones Navegacion -->
            <v-col cols="12">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="volver"
                            :loading="isloading"
                            :disabled="isloading"
                            color="blue"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-arrow-left-bold-outline</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Volver</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="saveTicket"
                            :loading="isloading"
                            :disabled="isloading"
                            color="success"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text"
                                >mdi-content-save-all</v-icon
                            >
                        </v-btn>
                    </template>
                    <span v-if="ticket.id">Actualizar</span>
                    <span v-else>Guardar</span>
                </v-tooltip>
                <v-tooltip v-if="ticket.id && edit" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="deleteTicket(ticket.id)"
                            :loading="isloading"
                            :disabled="isloading"
                            color="red"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text">mdi-trash-can</v-icon>
                        </v-btn>
                    </template>
                    <span>Eliminar</span>
                </v-tooltip>
                <v-tooltip v-if="ticket.id" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="openTareaDialog"
                            :loading="isloading"
                            :disabled="isloading"
                            color="primary"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text">mdi-file-tree</v-icon>
                        </v-btn>
                    </template>
                    <span>Generar Tarea</span>
                </v-tooltip>
                <v-tooltip v-if="ticket.id" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            fab
                            @click="gotoChat"
                            :loading="isloading"
                            :disabled="isloading"
                            color="primary"
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="white--text">mdi-chat</v-icon>
                        </v-btn>
                    </template>
                    <span>Chat</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <TareaDialogVue
            v-model="tareas_dialog"
            :id_proyecto="ticket.id_proyecto"
            :fecha="new Date().toISOString().slice(0, 10)"
            :tiempo="tiempo"
            :descripcion="ticket.titulo"
            :id_ticket="ticket.id"
        ></TareaDialogVue>
    </v-container>
</template>

<script>
import TareaDialogVue from "./TareaDialog.vue";
import RichTextComponent from "./RichTextComponent.vue";
import ArchivosComponentVue from "./ArchivosComponent.vue";
export default {
    components: {
        RichTextComponent,
        TareaDialogVue,
        ArchivosComponentVue,
    },
    data() {
        return {
            tareas_dialog: false,
            tiempo: 0,
            proyectos: [],
            index: 0,
            estados: [],
            departamentos: [],
            responsables: [],
            clientes: [],
            urgencia: [],
            ticket: {
                id: null,
                id_usuario: null,
                id_urgencia: null,
                id_departamento: null,
                id_estado_ticket: null,
                fecha_finalizacion: null,
                notificar: false,
                interno: false,
                archivos: [],
            },

            menu: false,
        };
    },
    watch: {
        "ticket.id_usuario"(val) {
            this.getProyectosByUser(val);
        },
    },
    created() {
        if (this.$route.query.id) {
            this.getTicket(this.$route.query.id);
        }

        this.getEstados();
        this.getDepartamentos();
        this.getResponsables();
        this.getAllClientes();
        this.getUrgencia();
    },
    methods: {
        TiempoRestante() {
            axios.get(`api/tiempo-restante`).then((res) => {
                this.tiempo = res.data.diff;
            });
        },
        gotoChat() {
            const este = this;
            axios.get(`api/get-chat/${this.ticket.id}`).then((res) => {
                este.$router.push(`/lista-chats?id=${este.ticket.id}`);
            });
        },
        openTareaDialog() {
            this.tareas_dialog = true;
            this.TiempoRestante();
        },
        getProyectosByUser(userId) {
            axios.get(`api/get-proyectos-by-user-id/${userId}/0`).then(
                (res) => {
                    this.proyectos = res.data;
                },
                (res) => {
                    this.$toast.error("Error consultando proyectos");
                }
            );
        },

        getEstados() {
            axios.get("api/get-estado-tickets").then((res) => {
                this.estados = res.data.success;
            });
        },
        getDepartamentos() {
            axios.get("api/get-departamentos").then((res) => {
                this.departamentos = res.data.success;
            });
        },
        getUrgencia() {
            axios.get("api/get-urgencia").then((res) => {
                this.urgencia = res.data.success;
            });
        },

        getResponsables() {
            axios.post(`api/get-usuarios-empleados-all`).then(
                (res) => {
                    this.responsables = res.data.users;
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },

        getAllClientes() {
            axios.get(`api/get-all-clientes`).then(
                (res) => {
                    this.clientes = res.data.users;
                    // for (let i = 0; i < this.usuarios.length; i++) {
                    //     const element = this.usuarios[i];
                    //     element.created_at = new Date(
                    //         element.created_at
                    //     ).toLocaleDateString();
                    // }
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },

        getTicket(id) {
            const self = this;
            axios.get(`api/get-ticket/${id}`).then(function (response) {
                self.ticket = response.data.success;
            });
        },
        saveTicket() {
            const self = this;
            let ruta = "";
            if (self.$route.query.id) {
                ruta = "update-ticket";
            } else {
                ruta = "create-ticket";
            }

            axios.post(`api/${ruta}`, this.ticket).then(function (response) {
                //self.$router.push("tickets");
                if (self.$route.query.id == null) {
                    self.ticket.id = response.data.data.id;
                    self.$router.push(`editar-ticket?id=${self.ticket.id}`);
                }

                self.$toast.sucs("Ticket Actualizado con Exito");
            });
        },
        deleteTicket(id) {
            const self = this;
            axios.get(`api/delete-ticket/${id}`).then(function (response) {
                self.$router.push("tickets");
            });
        },
        volver() {
            this.$router.push(`/tickets`);
        },
    },
    computed: {
        edit: function () {
            const role = this.role;
            return (
                role == 1 || role == 5 || role == 9 || role == 7 || role == 6
            );
        },
        role: function () {
            return localStorage.getItem("role");
        },
        isloading() {
            return this.$store.getters.getloading;
        },
        isDateSelectDisabled() {
            const rol = parseInt(localStorage.getItem("role"));
            // Si ticket.id no es nulo y el rol del user logeado no es administrador o administracion
            if (this.ticket.id != null && rol != 1 && rol != 5) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
