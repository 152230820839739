<template>
    <div>
        <div style="display: flex; flex-direction: row">
            <v-autocomplete
                @update:search-input="SearchCliente"
                v-model="id_cliente"
                :items="clientes"
                item-text="nombre"
                item-value="id"
                :label="label"
                :outlined="outlined"
                :prepend-icon="prependIcon"
            >
                <template v-slot:append-item>
                    <div v-intersect="endIntersect" />
                </template>
            </v-autocomplete>
        </div>
    </div>
</template>
<script>
import debounce from "lodash/debounce";

export default {
    props: [
        "value",
        "extra",
        "label",
        "item-text",
        "enabled",
        "outlined",
        "rol",
        "prepend-icon",
    ],
    data() {
        return {
            creado: null,
            rules: {
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    return pattern.test(value) || "E-mail Inválido.";
                },
                fiscal: (value) => {
                    const pattern = /^[A-Z]+$/i;
                    return pattern.test(value) || "E-mail Inválido.";
                },
            },
            id_cliente: null,
            cliente: {},
            dialog: false,
            users: [],
            update: false,
        };
    },
    watch: {
        value(val) {
            this.id_cliente = val;
        },
        id_cliente(val) {
            this.$emit("input", val);
        },
    },
    methods: {
        saveCliente() {
            axios.post("api/saveclientes", this.cliente).then(
                (res) => {
                    this.$store.dispatch("addCliente", res.data);
                    this.$toast.sucs("Cliente guardado satifatoriamente");

                    this.id_cliente = res.data.id;
                    this.creado = res.data;
                    // console.log(this.id);
                    this.dialog = false;
                },
                (res) => {
                    this.errors = res.response.data.errors;
                    this.$toast.error(
                        res.response.data.message ??
                            "Error al guardar el cliente"
                    );
                }
            );
        },
        SearchCliente: debounce(function (val) {
            this.$store.dispatch("searchCliente", {
                rol: this.rol,
                search: val,
            });
        }, 500),
        endIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.$store.dispatch("getClientesNext", { rol: this.rol });
            }
        },
    },
    mounted() {
        // console.log("aqui");
        this.$store.dispatch("getClientes", { rol: this.rol });
        if (this.value != null) {
            this.id_cliente = this.value;
        }
        // this.getAgente();
    },
    computed: {
        agenteItems() {
            return this.users.map((user) => {
                return {
                    text: user.name + " " + user.apellidos,
                    value: user.id,
                };
            });
        },
        clientes() {
            ///console.log("siva");
            let clientes_temp = this.$store.getters.getclientes;
            if (this.extra?.nombre != null) {
                clientes_temp.push(this.extra);
            }
            if (this.creado) {
                clientes_temp.push(this.creado);
            }
            // console.log(clientes_temp);
            return clientes_temp;
        },
    },
};
</script>
